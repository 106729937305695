import React from 'react';
import aboutimg from '../../image/about.jpg';
import './index.css';


function About() {
    return (
        <div>
            <div className='title'><h1>
                关于我们
                </h1>
            </div>
            <div >
                <img className='c-img' alt='' src={aboutimg} />
            </div>
            <div className='text'>
                <p>
                炫名坊电子（上海）有限公司成立于2008年8月，主营充电器、蓝牙设备和智能手表等消费类电子产品。公司以设计研发为主，从集成电路设计、软件、云平台到产品的外观结构设计，都自主完成。尤其在儿童电话手表领域，公司针对海外用户的需求所达到的技术水平已经位于行业前列。
                </p>
                </div>
            <div className='text'>
                <p>
                2018年在美国加利福尼亚州设立子公司作为全球营销的总部。2018年底在中国深圳设立办事处，并与2020年初设立全资子公司-炫名坊科技（深圳）有限公司，主要负责软硬件开发和供应链的管理。 
                </p>
                </div>
            <div className='text'>
                <p>
                目前已经有超过60个国家的20万用户在使用公司的儿童智能手表。公司立志于打造全球最好的儿童智能手表，为孩子们的成长提供安全保障和更大的沟通自由。  
                </p>
            </div>
            
        </div>
    )
}

export default About