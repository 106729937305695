import React from 'react';
import './index.css';
import { Card, Timeline } from 'antd';
import city from '../../image/city.jpg';
import z2016 from '../../image/2016.jpg';
import z2017 from '../../image/2017.jpg';
import z2020 from '../../image/2020.jpg';
import z2021 from '../../image/2021.jpg';

const { Meta } = Card;
const items = [
    {
        children: (<Card title="2016年香港电子展"
        cover={<img alt='' src={z2016}/>}
    >
        <Meta description="本次的电子展是我们所有展会中规模最大的，随着亚洲尤其我国市场的迅速发展，我们期待能在未来能够抓住这个历史的机遇。"/>
    </Card>),
        label: "2016年10月20日"
    },
    {
        children: (<Card title="2017年CES展会"
        cover={<img alt='' src={z2017}/>}
    >
        <Meta description="这是我们第四次参加CES展了，本次展会我们总共接待了三百多来自全球的顾客，比往年增长25%，加油！"/>
    </Card>),
        label: "2017年1月10日"
    },
    {
        children: (<Card title="拥抱未来"
            cover={<img alt='' src={city}/>}
        >
            <Meta description="为了能够更好的和产业链融合，深圳-我们来了。"/>
        </Card>),
        label: "2018年8月30日"
    },
    {
        children: (<Card title="2020年公司团结"
        cover={<img alt='' src={z2020}/>}
    >
        <Meta description="这是我们深圳公司成立后的第一次团建，小伙伴们在泰国玩的可高兴了。其中丛林穿越那高耸入云的索桥和30米的蹦极可把大家的恐高症一次治愈了。"/>
    </Card>),
        label: "2020年1月15日"
    },
    {
        children: (<Card title="2021年公司团建"
        cover={<img alt='' src={z2021}/>}
    >
        <Meta description="尽管疫情并没有离我们远去，但是大家还是在南昆山渡过了一个令人难忘的假期。撸串、爬山、潜水，精彩满满。"/>
    </Card>),
        label: "2021年9月10日"
    },
]


function News() {
    return (
        <>
        <div className='gonsinews'>公司动态</div>
        <div className='timeline'>
           <Timeline mode='alternate' items={items} /> 
        </div>
        </>
    )
}

export default News