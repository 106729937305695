import React from 'react';
import './index.css';
import businessman from '../../image/businessman.jpg'


function Contactus() {
    return (
        <div className='content'>
           <div className='title'>联系我们</div>
            <img className='img' src={businessman} alt="" />
            <div className='gongsi'>炫名坊电子（上海）有限公司</div>
            <div className='address'>上海市普陀区真光路 1473 弄 3 号 2236 室</div>
            <div className='address'>电话: 021-62307579</div>
            <div className='address'>邮编: 200333</div>
        </div>
    )
}

export default Contactus