import './App.css';
import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DashbordLayout from "./pages/layoutpages"
import Home from './pages/home';
import About from './pages/about';
import Product from './pages/product';
import News from './pages/news';
import Contactus from './pages/contactus';

function App() {
  return (
    <BrowserRouter>
    <Fragment>
    <div className='App' >
      <Routes>
        <Route path="/" element={<DashbordLayout/>}>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/product" element={<Product/>}></Route>
          <Route path="/news" element={<News/>}></Route>
          <Route path="/contactus" element={<Contactus/>}></Route>
        </Route>
      </Routes>
    </div>
    </Fragment>
    </BrowserRouter>
  );
}

export default App;
