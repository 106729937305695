import { Carousel,Button,Card } from 'antd';
import React from 'react';
import './index.css';
import pop from '../../image/x-pop.jpg';
import watch from '../../image/4G watch.jpg';
import tereo from '../../image/x-tereo.jpg';
import {Link} from 'react-router-dom'
const { Meta } = Card;

function Home(){
    return(
      <div>   
        <Carousel autoplay="true">
          <div>
            <img className='carousel' alt=''></img>
          </div>
        </Carousel>
        <div >
          <h1>关于我们</h1>
          <p className='content-p'>炫名坊电子（上海）有限公司成立于2008年8月，主营充电器、蓝牙设备和智能手表等消费类电子产品。公司以设计研发为主，从集成电路设计、软件、云平台到产品的外观结构设计，都自主完成。尤其在儿童电话手表领域，公司针对海外用户的需求所达到的技术水平已经位于行业前列。
          </p>
          <div className='c-button'>
          <Button size='large'><Link to="/about">更多信息</Link></Button>
          </div>
        </div>
        <div>
          <h2>公司背景和产品介绍</h2>
          <iframe  className='ytb-src' title='youtube' src='https://www.youtube.com/embed/A2gW6hURJCY?enablejsapi=1'/>
        </div>
        <div>
          <h2 className='main-product'>主营产品</h2>
          <div className='card'>
            <Card 
            className='card-inner'
            hoverable
            cover={<img alt="example" src={watch} />}
            >
              <Meta title="4G 儿童手表电话" />
            </Card>
            <Card
              className='card-inner'
              hoverable
              cover={<img alt='' src={pop}/>}
            >
              <Meta title="X-POP 蓝牙音箱" />
            </Card>
            <Card
              className='card-inner'
              hoverable
              cover={<img alt='' src={tereo}/>}
            >
              <Meta title="X-TEREO蓝牙音箱" />
            </Card>
          </div>
        </div>
      </div>
    )
}

export default Home