import React from 'react';
import {Card} from 'antd';
import './index.css';
import pop from '../../image/x-pop.jpg';
import watch from '../../image/4G watch.jpg';
import tereo from '../../image/x-tereo.jpg';
const { Meta } = Card;

function Product(){
    return (
        <div>
            <h1 className='title'>产品</h1>
            <div className='card'>
            <Card 
            className='card-inner'
            hoverable
            cover={<img alt="example" src={watch} />}
            >
              <Meta title="4G 儿童手表电话" />
            </Card>
            <Card
              className='card-inner'
              hoverable
              cover={<img alt='' src={pop}/>}
            >
              <Meta title="X-POP 蓝牙音箱" />
            </Card>
            <Card
              className='card-inner'
              hoverable
              cover={<img alt='' src={tereo}/>}
            >
              <Meta title="X-TEREO蓝牙音箱" />
            </Card>
            </div>
        </div>
    )
}

export default Product