import './index.css';
import React from 'react';
import { Layout,  Menu } from 'antd';
import {Outlet } from 'react-router-dom'
import {Link} from 'react-router-dom'

const { Header, Content, Footer } = Layout;
const items = [
  {
    label: <Link to='/' >首页</Link>,
    key: '/',
  },
  {
    label: <Link to='/about' >关于我们</Link>,
    key: 'about',
  },
  {
    label:<Link to='/product' >产品</Link>,
    key: 'product',
  },
  {
    label: <Link to='/news' >公司动态</Link>,
    key: 'news',
  },
  {
    label: <Link to='/contactus' >联系我们</Link>,
    key: 'contactus',
  }
];


function DashbordLayout() {
  const onClick =(e)=>{
    console.log(e)
  }
  return (
    <div >
      <Layout>
      <Header  className='header'>
        <div className='demo-log' />
        <Menu theme="dark" className='header-menu' onClick={onClick} mode="horizontal" items={items} />
      </Header >
      <Content className='content' >
        <Outlet/>
      </Content>
      <Footer className='footer'>
        炫名坊电子（上海）有限公司 ©{new Date().getFullYear()} <a href="https://beian.miit.gov.cn/">沪ICP备2022030220号-1</a>
      </Footer>
      </Layout>
    </div>

  );
}

export default DashbordLayout;
